import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyCp5Ry-rppMORoMXb7fOOVpJO_qpE0tpWE",
  authDomain: "gasstation-ba472.firebaseapp.com",
  projectId: "gasstation-ba472",
  storageBucket: "gasstation-ba472.firebasestorage.app",
  messagingSenderId: "633544446987",
  appId: "1:633544446987:web:8407e183bc79a4d6d7a30e",
  measurementId: "G-ZG5RDY5PPL"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };