import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { getBooks } from "./actions";
import { booksKeys } from "./constants";

const useBooks = (place = "Офис") => {
  const query = useQuery({
    queryKey: [...booksKeys.books, place],
    queryFn: () => getBooks(place),
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 403) {
        return;
      }
    },
  });

  return query;
};

export default useBooks;
