import React from "react";
import { QueryClientProvider } from "./api/queryClient";
import BookingForm from "./components/BookingForm";
import Canvas from "./components/Canvas/Canvas";
import DayBookingBlock from "./components/DayBookingBlock";
import "./index.css";
import { useState } from "react";

function App() {
  const [place, setPlace] = useState("Солнечное");

  return (
    <QueryClientProvider>
      <Canvas />
      <BookingForm place={place} setPlace={setPlace} />
      <DayBookingBlock place={place} />
    </QueryClientProvider>
  );
}

export default App;
