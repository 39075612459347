import httpClient from "../../httpClient";
import queryClient from "../../queryClient";
import { booksKeys } from "./constants";
import { db } from "../../../tools/firebase";
import { doc, getDoc, setDoc, updateDoc, deleteField } from "firebase/firestore";

export const getBooks = async (place) => {
  try {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const todayFormatted = today.toISOString().split('T')[0];
    const tomorrowFormatted = tomorrow.toISOString().split('T')[0];

    // Создаем пустые слоты времени
    const createEmptyTimeSlots = (date) => {
      const slots = {};
      for (let hour = 0; hour < 24; hour++) {
        const formattedHour = hour.toString().padStart(2, '0');
        for (let minutes of ['00']) {
          const time = `${formattedHour}:${minutes}`;
          slots[time] = null;
        }
      }
      return {
        date,
        slots
      };
    };

    const bookingRef = doc(db, "bookings", place);
    const bookingDoc = await getDoc(bookingRef);
    
    let bookingData = {};
    if (!bookingDoc.exists()) {
      // Если документ не существует, создаем новый с пустыми слотами
      bookingData = {
        [todayFormatted]: createEmptyTimeSlots(todayFormatted),
        [tomorrowFormatted]: createEmptyTimeSlots(tomorrowFormatted)
      };
      await setDoc(bookingRef, bookingData);
    } else {
      bookingData = bookingDoc.data();
      
      // Создаем новый объект только с актуальными датами
      const newBookingData = {
        [todayFormatted]: bookingData[todayFormatted] ? 
          { ...bookingData[todayFormatted], date: todayFormatted } : 
          createEmptyTimeSlots(todayFormatted),
        [tomorrowFormatted]: bookingData[tomorrowFormatted] ? 
          { ...bookingData[tomorrowFormatted], date: tomorrowFormatted } : 
          createEmptyTimeSlots(tomorrowFormatted)
      };
      
      // Перезаписываем документ с новыми данными
      await setDoc(bookingRef, newBookingData);
      bookingData = newBookingData;
    }

    // Преобразуем данные в нужный формат
    return {
      bookings: {
        today: bookingData[todayFormatted],
        tomorrow: bookingData[tomorrowFormatted]
      }
    };
  } catch (err) {
    console.error("Ошибка при получении данных:", err);
    throw err;
  }
};

export const setBookForHourRequest = async (bookInfo) => {
  try {
    const { date, time, userName, place } = bookInfo;
    console.log('Booking info:', { date, time, userName, place });

    const today = new Date();
    const isToday = new Date(date).toDateString() === today.toDateString();
    
    const bookingRef = doc(db, "bookings", place);
    const bookingDoc = await getDoc(bookingRef);
    
    if (!bookingDoc.exists()) {
      console.log('Creating new document');
      // Create new document if it doesn't exist
      const slots = {};
      for (let hour = 12; hour < 24; hour++) {
        const formattedHour = hour.toString().padStart(2, '0');
        for (let minutes of ['00', '30']) {
          const slotTime = `${formattedHour}:${minutes}`;
          slots[slotTime] = slotTime === time ? 
            { userName, isToday, time } : 
            null;
        }
      }

      const newDoc = {
        [date]: {
          date,
          slots
        }
      };
      console.log('New document structure:', newDoc);
      await setDoc(bookingRef, newDoc);
    } else {
      console.log('Updating existing document');
      const bookingData = bookingDoc.data();
      console.log('Current booking data:', bookingData);
      
      // Если дата существует, используем её слоты, иначе создаем новые
      let dateSlots = {};
      if (bookingData[date]?.slots) {
        dateSlots = { ...bookingData[date].slots };
      } else {
        // Создаем все слоты
        for (let hour = 12; hour < 24; hour++) {
          const formattedHour = hour.toString().padStart(2, '0');
          for (let minutes of ['00', '30']) {
            const slotTime = `${formattedHour}:${minutes}`;
            dateSlots[slotTime] = null;
          }
        }
      }

      // Проверяем, не занят ли слот
      if (dateSlots[time] !== null) {
        console.log('Slot is already booked:', dateSlots[time]);
        throw new Error("Слот уже забронирован");
      }

      // Обновляем слот
      dateSlots[time] = { userName, isToday, time };

      const updatedDoc = {
        ...bookingData,
        [date]: {
          date,
          slots: dateSlots
        }
      };
      console.log('Updated document structure:', updatedDoc);
      await setDoc(bookingRef, updatedDoc);
    }

    console.log('Booking successful');
    alert(
      `Слот на ${
        new Date(date).toLocaleDateString("ru-RU", {
          day: "numeric",
          month: "long",
        })
      } ${time} успешно забронирован.`
    );
  } catch (err) {
    console.error('Booking error:', err);
    alert(err.message || "Ошибка при бронировании слота.");
  }
};
