import React from "react";
import { useBooks } from "../api";
import Card from "./Card";
import "./DayBookingBlock.css"; // Подключение CSS

const DayBookingBlock = ({ place}) => {
  const booksQuery = useBooks(place);

  if (!booksQuery.isSuccess) {
    return null;
  }

  const { today, tomorrow } = booksQuery.data.bookings;

  return (
    <>
    <h2 style={{ textAlign: 'center', width: 'fit-content', borderRadius: '8px', margin: '10px auto', padding: '10px', backgroundColor: '#f9f9f9' }}>{place}</h2>

    <div className="day-booking-block">
      <Card bookings={today} isTomorrow={false} />
      <Card bookings={tomorrow} isTomorrow={true} />
    </div>
    </>

  );
};

export default DayBookingBlock;
