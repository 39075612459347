import React from "react";

const Card = ({ isTomorrow = false, bookings }) => {
  if (!bookings || !bookings.slots) return null;

  const renderBookRow = (time) => {
    const booking = bookings.slots[time];
    const currentHour = new Date().getHours();
    const currentMinutes = new Date().getMinutes();
    const [bookingHour, bookingMinutes] = time.split(':').map(Number);

    // Для текущего часа проверяем минуты
    if (!isTomorrow && 
        (bookingHour < currentHour || 
         (bookingHour === currentHour && bookingMinutes <= currentMinutes))) {
      return null;
    }

    return (
      <div key={time} style={styles.time}>
        <div>
          {time}
          {booking?.userName && `: ${booking.userName}`}
        </div>
      </div>
    );
  };

  return (
    <div style={styles.card}>
      <h3 style={styles.title}>
        {new Date(bookings.date).toLocaleDateString("ru-RU", {
          weekday: "long",
          day: "numeric",
          month: "long",
        })}
      </h3>
      <div style={styles.content}>
        <div style={styles.column}>
          {Object.keys(bookings.slots)
            .sort((a, b) => {
              const [hoursA, minutesA] = a.split(':').map(Number);
              const [hoursB, minutesB] = b.split(':').map(Number);
              return hoursA * 60 + minutesA - (hoursB * 60 + minutesB);
            })
            .map(renderBookRow)}
        </div>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "16px",
    width: "320px",
    margin: "16px auto",
    backgroundColor: "#f9f9f9",
  },
  title: {
    textAlign: "center",
    marginBottom: "16px",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
  column: {
    flex: 1,
    margin: "0 8px",
  },
  time: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "10px",
    marginTop: "10px",
    fontWeight: "bold",
    borderBottom: "1px solid #ccc",
  },
  entry: {
    marginBottom: "8px",
  },
  text: {
    color: "#555",
  },
  input: {
    width: "100%",
    padding: "4px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
};

export default Card;
