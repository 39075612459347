import React, { useEffect, useState } from "react";
import { useBooks } from "../api";
import { setBookForHourRequest } from "../api/resources/booking/actions";
import { useQueryClient } from "@tanstack/react-query";

// Функция для генерации времён с 12:00 до 24:00
const generateTimes = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    for (let minutes of ["00", "30"]) {
      times.push(`${formattedHour}:${minutes}`);
    }
  }
  return times;
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const isTimeSlotAvailable = (time, selectedDay) => {
  const now = new Date();
  const [slotHour, slotMinutes] = time.split(":").map(Number);
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();

  if (selectedDay === "tomorrow") return true;

  // Для текущего часа проверяем минуты
  if (slotHour === currentHour) {
    return slotMinutes > currentMinutes;
  }

  // Для остальных часов просто сравниваем с текущим
  return slotHour > currentHour;
};

const BookingForm = ({ place, setPlace }) => {
  const [selectedDay, setSelectedDay] = useState("today");
  const [selectedTime, setSelectedTime] = useState("");
  const [name, setName] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    setSelectedTime("");
  }, [selectedDay]);

  const times = generateTimes();
  const booksQuery = useBooks(place);

  if (!booksQuery.isSuccess) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedTime || !name) {
      alert("Пожалуйста, заполните все поля!");
      return;
    }

    const newDate = new Date();
    if (selectedDay === "tomorrow") {
      newDate.setDate(newDate.getDate() + 1);
    }

    try {
      await setBookForHourRequest({
        date: formatDate(newDate),
        time: selectedTime,
        userName: name,
        place,
      });

      // Инвалидируем кэш для обновления данных
      await queryClient.invalidateQueries(["books", place]);

      setSelectedTime("");
      setName("");
    } catch (error) {
      console.error("Ошибка при бронировании:", error);
    }
  };

  const dayBookings = booksQuery.data.bookings[selectedDay];

  return (
    <form style={styles.form} onSubmit={handleSubmit}>
      <h2 style={styles.title}>Бронирование</h2>

      <label style={styles.label}>
        Место:
        <select
          value={place}
          onChange={(e) => setPlace(e.target.value)}
          style={styles.select}
        >
          <option value="Солнечное">Солнечное</option>
          <option value="Офис">Офис</option>
        </select>
      </label>

      <label style={styles.label}>
        День:
        <select
          value={selectedDay}
          onChange={(e) => setSelectedDay(e.target.value)}
          style={styles.select}
        >
          <option value="today">Сегодня</option>
          <option value="tomorrow">Завтра</option>
        </select>
      </label>

      <label style={styles.label}>
        Время:
        <select
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          style={styles.select}
        >
          <option value="" disabled>
            Выберите время
          </option>
          {times.map((time) => {
            // Проверяем наличие слотов
            if (!dayBookings?.slots) return null;
            
            const slot = dayBookings.slots[time];
            if (isTimeSlotAvailable(time, selectedDay) && slot === null) {
              return (
                <option key={time} value={time}>
                  {time}
                </option>
              );
            }
            return null;
          })}
        </select>
      </label>

      <label style={styles.label}>
        Имя:
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Введите ваше имя"
          style={styles.input}
        />
      </label>

      <button type="submit" style={styles.button}>
        Забронировать
      </button>
    </form>
  );
};

const styles = {
  form: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  title: {
    textAlign: "center",
    marginBottom: "16px",
  },
  label: {
    marginBottom: "12px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "8px",
    marginTop: "4px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  input: {
    width: "95%",
    padding: "8px",
    marginTop: "4px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    marginTop: "16px",
    padding: "10px",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#007BFF",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default BookingForm;
